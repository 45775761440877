import crypto from 'crypto'
import React from 'react'
import PropTypes from 'prop-types'
import nextWithApollo from 'next-with-apollo'
import { ApolloProvider, ApolloClient, InMemoryCache, HttpLink, ApolloLink, concat } from '@apollo/client'
import { getDataFromTree } from '@apollo/client/react/ssr'
import { createUploadLink } from 'apollo-upload-client'
import axios from 'axios'

import { nextjsApiHost } from 'helpers/nextjsApiCall'

import ProjectOffer from './typePolicies/ProjectOffer'
import OfferPriceIndication from './typePolicies/OfferPriceIndication'

const PageWithApolloProvider = ({
  Page,
  props: {
    apollo,
    ...restPropsToForward
  },
}) => (
  <ApolloProvider client={apollo}>
    <Page {...restPropsToForward} />
  </ApolloProvider>
)

PageWithApolloProvider.propTypes = {
  Page: PropTypes.elementType.isRequired,
  props: PropTypes.shape({
    apollo: PropTypes.object.isRequired,
  }).isRequired,
}

const linkOptions = {
  uri: `${nextjsApiHost()}/graphql`,
  credentials: 'same-origin',
}

export const withApollo = nextWithApollo(
  ({ ctx, headers, initialState }) => (
    new ApolloClient({
      link: concat(
        new ApolloLink((operation, forward) => {
          const newHeaders = {}
          const csrfToken = axios.defaults.headers.common['X-CSRF-TOKEN']

          if (csrfToken) {
            newHeaders['X-CSRF-TOKEN'] = csrfToken
          }
          newHeaders['X-NONCE'] = Buffer.from(crypto.randomBytes(16)).toString('base64')

          if (ctx?.req) {
            newHeaders.cookie = ctx.req.get('cookie')

            if (!newHeaders.cookie && ctx.req.sessionId) {
              newHeaders.cookie = `_session_id=${ctx.req.sessionId}`
            }

            newHeaders['x-forwarded-host'] = ctx.req.get('host').split(':')[0]
          }

          operation.setContext({
            headers: {
              ...operation.headers,
              ...newHeaders,
            },
          })

          return forward(operation)
        }),
        ApolloLink.split(
          (operation) => operation.getContext().hasUpload,
          createUploadLink(linkOptions),
          new HttpLink(linkOptions),
        ),
      ),
      cache: new InMemoryCache({
        typePolicies: {
          ProjectOffer,
          OfferPriceIndication,
        },
      }).restore(initialState || {}),
      resolvers: {},
    })
  ),
  {
    getDataFromTree,
    render: PageWithApolloProvider,
  },
)
